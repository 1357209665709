

import {
  Prop, Vue, Component,
} from 'vue-property-decorator'

@Component({})
export default class Date extends Vue {
  @Prop() private label!: string

  @Prop() private date!: string
}
