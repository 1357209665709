

import { Component, Vue, Prop } from 'vue-property-decorator'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class Button extends Vue {
  @Prop({ required: true }) private url!: string

  @Prop({ required: false }) private title!: string

  @Prop({ required: false }) private description!: string
}
