

import { Component, Vue, Prop } from 'vue-property-decorator'
import SvgIcon from '@/components/atoms/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
})
export default class Button extends Vue {
  @Prop({ required: true }) private label!: string

  @Prop() private iconType!: string

  @Prop({ default: null }) private href!: string
}
